import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "./assets/pngs/logo.png";
import "./nav.css";
import menu1 from "./assets/pngs/menu1.png";

const Navbar = () => {
  const location = useLocation();
  const [showDotsDropdown, setShowDotsDropdown] = useState(false);
  const isHomePage = location.pathname === "/home";

  useEffect(() => {
    const closeDropdown = (event) => {
      if (showDotsDropdown && !event.target.closest(".dots__profile")) {
        setShowDotsDropdown(false);
      }
    };

    document.addEventListener("click", closeDropdown);

    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, [showDotsDropdown]);

  const toggleDotsDropdown = () => {
    setShowDotsDropdown(!showDotsDropdown);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <nav className="navbar">
      <div className="navbar__logo">
        <NavLink to="/home">
          <img src={logo} alt="Polish Eyewear" />
        </NavLink>
      </div>

      <ul className="navbar__links">
        <li>
          <NavLink exact to="/home" activeClassName="active" onClick={scrollToTop}>
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/CategoryCatalogue" activeClassName="active" onClick={scrollToTop}>
            Eyewear
          </NavLink>
        </li>
        <li>
          <NavLink to="/SunglassCatalogue" activeClassName="active" onClick={scrollToTop}>
            Sunglass
          </NavLink>
        </li>
        <li>
          <NavLink to="/About" onClick={scrollToTop}>About</NavLink>
        </li>
      </ul>

      <div className="dots__profile" onClick={toggleDotsDropdown}>
        <img src={menu1} alt="3dots" className="dots__profile-img" />
        {showDotsDropdown && (
          <ul className="dots__dropdown">
            <li><Link to='/home'>Home</Link></li>
            <li><Link to='/CategoryCatalogue'>Eyewear</Link></li>
            <li><Link to='/SunglassCatalogue'>Sunglass</Link></li>
            <li><Link to='/About'>About</Link></li>
          </ul>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
