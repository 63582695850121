const firebaseConfig = {
  apiKey: "AIzaSyAzjLKgSzFI3Ku3S4KdFdYPI7zrSIF3clk",
  authDomain: "polisheyewearit2024.firebaseapp.com",
  projectId: "polisheyewearit2024",
  storageBucket: "polisheyewearit2024.appspot.com",
  messagingSenderId: "495904755768",
  appId: "1:495904755768:web:2a24454c328aaee87796cd",
  measurementId: "G-HY1MHV526T"
};

export default firebaseConfig;
