import React from "react";
import "./About.css";
import img1 from "./assets/pngs/focus.png";
import logo from "./assets/pngs/logo.png";
import "./animation.css";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <div className="gradient-bgg">
      <div className="about-container">
        <div className="about-section">
          <h1>About Polish Eyewear</h1>
          <p>
            Polish Eyewear epitomizes perfection in design. The science of style
            and fashion are the key elements in the development of every piece
            of Polish Eyewear. Our product team lays utmost emphasis on creating
            eyewear that is unique and fashion forward; and gives maximum
            comfort to the consumer.
          </p>
          <h1>Our Mission and Values</h1>
          <p>
          At Polish Eyewear, our mission is to empower individuals to express themselves through eyewear, offering a wide range of styles and options to suit every taste and budget. We're committed to delivering exceptional value and service, ensuring that every customer leaves satisfied with their purchase.
          </p>
          <h1>Quality Assurance and Service</h1>
          <p>
          Quality is our top priority at Polish Eyewear. We partner with trusted manufacturers known for their craftsmanship and attention to detail, ensuring that every frame meets our stringent quality standards. With rigorous quality control measures in place, you can trust that you're investing in eyewear that's built to last.
          </p>
          <p>At Polish Eyewear, we're committed to providing an exceptional shopping experience from start to finish. Our knowledgeable team is here to assist you with personalized recommendations, expert fittings, and ongoing support, whether you're browsing online or visiting one of our retail locations.</p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
